const storage = {
    setToken(value) {
        localStorage.setItem("token", JSON.stringify(value));
    },
    getToken() {
        return JSON.parse(localStorage.getItem("token"));
    },
    rmToken() {
        localStorage.removeItem("token");
    },
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get(key) {
        return JSON.parse(localStorage.getItem(key));
    },
    remove(key) {
        localStorage.removeItem(key);
    },
};

export default storage;

