import axios from "axios";
/*创建axios 实例*/
const zbhAdminService = axios.create({
    /*api的baseURL*/
    baseURL:
        process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_REQUEST_URL
            : "/",
    /*请求超时时间*/
    timeout: 60000
});
/*request 拦截器*/
zbhAdminService.interceptors.request.use(
    config => {
        let url = config.url;
        //配置密匙
        if (url.includes("authservice")){
            config.headers["secret"] = "DujXCSUzOwjSyMCcGfOH6lBIzjBDyeTNDbCzMRT9CvUtw63GHXmI4CNQLm6IUNme";
        }
        //配置token
        let token = JSON.parse(localStorage.getItem("token"))
        if (url.includes("webgroupservice")) {
            config.headers["Authorization"] ="Bearer "+ token;
        }
        return config;
    },
    error => {
       /*处理请求出错*/
        return Promise.reject(error);
    }
);
/*response拦截器*/
zbhAdminService.interceptors.response.use(res => {
            return res;
        },
    error => {
        if (error.response.status === 401) {
            window.$router.push("/login")
            return ;
        }
        /*处理response出错逻辑*/
        return Promise.reject(error);
    }
);
export default zbhAdminService;
