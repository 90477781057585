<template>
  <div id="app">
    <!--路由占位-->
    <router-view v-if="isShow"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{ isShow:true}
  },
  provide(){
    return {reload : this.reload}
  },
  methods: {
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true
      });
    },
  },
}
</script>

<style>
html,body,#app {
  width: 100%;
  height: 100%;
  margin: 0px;
}
</style>