import request from "@/utils/request";

export const  userLogin =(dataObj) =>{
    return request({
        url: "/authservice/webGroup/login",
        method:"post",
        data:dataObj
    })
}

export const  codeLogin =(data) =>{
    return request({
        url: "/authservice/webGroup/codeLogin",
        method:"post",
        data:data
    })
}

export const  resetPwd =(data) =>{
    return request({
        url: "/authservice/webGroup/resetPassword",
        method:"post",
        data:data
    })
}

export const  userHasReg =(username) =>{
    return request({
        url: "/authservice/webGroup/userHasReg?username="+username,
        method:"get",
    })
}

export const  getMobileCode =(data) =>{
    return request({
        url: "/authservice/webGroup/getMobileCode",
        method:"post",
        data:data
    })
}

export const  isAuthGroup =(username) =>{
    return request({
        url: "/authservice/webGroup/isAuthLogin?username="+username,
        method:"get",
    })
}
