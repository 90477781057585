import request from "@/utils/request";
/*群成员申请列表*/

export const  memberApplyPageList =(query) =>{
    return request({
        url: "/webgroupservice/qunMemberApply/pageList",
        method:"get",
        params:query
    })
}
/*处理入群申请*/
export const  dealApply =(obj) =>{
    return request({
        url: "/webgroupservice/qunMemberApply/dealApply",
        method:"post",
        data:obj
    })
}
