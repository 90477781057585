import CryptoJS from "crypto-js"

const zbhKey = "ylzlnzbf@2020smq"
const key = CryptoJS.enc.Utf8.parse(zbhKey);
const iv = CryptoJS.enc.Utf8.parse(zbhKey);
/*加密密码*/
export function Encrypt(plainPwd) {

    var encrypted = CryptoJS.AES.encrypt(plainPwd, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    var cipherText = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return cipherText;

}