<template>
  <div class="add-group-box">
    <div class="box-pc" v-show="pcShow">
      <div class="group-box-pc">
        <div class="box-top"></div>
        <div class="box-bottom">
          <div class="line-box-w100 font-color">
            您正在申请加入{{qunInfo.qunName}}
          </div>
          <!--手机号-->
          <div class="line-box-seize"></div>
          <div class="line-box-w90">
            <div class="line-info-img float-l">
              <img src="../../assets/img/joinGroup/phone.png">
            </div>
            <div class="line-info-input float-l">
              <input type="text" v-model="joinForm.username" placeholder="请输入11位手机号码" />
            </div>
          </div>
          <div class="line-box-seize"></div>
          <!--验证码-->
          <div class="line-box-w90">
            <div class="line-info-img float-l">
              <img src="../../assets/img/joinGroup/code.png">
            </div>
            <div class="line-info-input-code float-l">
              <input type="text" v-model="joinForm.code"  placeholder="请输入验证码"/>
            </div>
            <div class="line-info-code-btn float-l">
              <el-button class="get-code-button" @click="validateAndGetCode">{{ msgText }}</el-button>
            </div>
          </div>
          <div class="line-box-seize"></div>
          <!--真实姓名-->
          <div class="line-box-w90">
            <div class="line-info-img float-l">
              <img src="../../assets/img/joinGroup/name.png">
            </div>
            <div class="line-info-input float-l">
              <input type="text" v-model="joinForm.memberName"  placeholder="请输入群内名称"/>
            </div>
          </div>
          <div class="line-box-seize"></div>
          <div class="line-box-seize"></div>
          <div class="line-btn-w90">
            <input type="button" class="add-group-btn" @click="userJionQun" value="提交入群申请"/>
          </div>
        </div>
      </div>
    </div>
    <!--App端-->
    <div class="group-box-app" v-show="appShow">
      <div class="box-top"></div>
      <div class="box-bottom">
        <div class="line-box-w100-app font-color">
          您正在申请加入{{qunInfo.qunName}}
        </div>
        <!--手机号-->
        <div class="line-box-w90-app">
          <div class="line-info-img float-l">
            <img src="../../assets/img/joinGroup/phone.png">
          </div>
          <div class="line-info-input float-l">
            <input type="text" v-model="joinForm.username" placeholder="请输入11位手机号码"/>
          </div>
        </div>
        <div class="line-box-seize"></div>
        <!--验证码-->
        <div class="line-box-w90-app">
          <div class="line-info-img float-l">
            <img src="../../assets/img/joinGroup/code.png">
          </div>
          <div class="line-info-input-code float-l">
            <input type="text"  v-model="joinForm.code" placeholder="请输入验证码"/>
          </div>
          <div class="line-info-code-btn float-l">
            <el-button class="get-code-button"  @click="validateAndGetCode" >{{ msgText }}</el-button>
          </div>
        </div>
        <div class="line-box-seize"></div>
        <!--真实姓名-->
        <div class="line-box-w90-app">
          <div class="line-info-img float-l">
            <img src="../../assets/img/joinGroup/name.png">
          </div>
          <div class="line-info-input float-l">
            <input type="text" v-model="joinForm.memberName" placeholder="请输入群内名称"/>
          </div>
        </div>
        <div class="line-box-seize"></div>
        <div class="line-box-w90-app-noshadow">
          <input type="button"   class="add-group-btn" @click="userJionQun" value="提交入群申请"/>
        </div>
      </div>
    </div>
    <!--加群成功蒙层-->
    <div class="cover" v-show="coverBox">
      <div class="success-box">
        <div class="success-info-box">
          <div class="success-img"></div>
          <div class="success-info">
            <div class="info01">入群申请已提交</div>
            <div class="info02">管理员正在审核，请耐心等待</div>
          </div>
          <div class="success-info">
            <div class="know-btn-box">
              <input type="button" class="add-group-btn" value="我知道了" @click="closeBox"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const MSGINIT = "获取验证码";
  const MSGSCUCCESS = "${time}秒后重发";
  const MSGTIME = 60;
  let pcShow = true;
  let appShow = false;
  import {authQunInfo,joinQunApply} from "@/api/admin/qun"
  import {userHasReg,getMobileCode} from "@/api/user/login"
  import { validatenull ,isMobile} from '@/utils/validate'
export default {
  name: "joinGroup",
  data(){
    return{
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      coverBox: false,
      qunInfo:{},
      joinForm:{
        username:"",
        code:"",
        qunId:'',
        memberName:'',
      },
      codeForm:{
        mobile:"",
        type:""
      },
      rules: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        memberName: [{ required: true, message: '请输入群内名称', trigger: 'blur' }],
      },
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'overflow-y: hidden;')
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (flag){
      this.pcShow = false;
      this.appShow = true;
    }else {
      this.pcShow = true;
      this.appShow = false;
    }
  },
  created() {
    let query=this.$route.query;
    debugger
    this.queryQunInfo(query.id)
    console.log(query.mobile)
    if (query.mobile!=undefined){
      this.joinForm.username = query.mobile
    }
    console.log(query)
  },
  methods:{
    queryQunInfo(qunId){
      authQunInfo(qunId).then(res =>{
        if (res.data.code === 1){
          this.qunInfo = res.data.data
        }else {
          this.$message.error("请求出错，请联系管理员")
        }
      })
    },
    validateAndGetCode(){
      if (this.msgKey) return;
      let username = this.joinForm.username
      if (validatenull(username)){
        this.$message.warning("请输入手机号码")
        return
      }
      if(!isMobile(username)){
        this.$message.warning("手机号码格式不正确")
        return
      }
      debugger
      this.codeForm.mobile = this.joinForm.username
      /*查验用户是否注册*/
      userHasReg(this.joinForm.username).then(res =>{
        if (res.data.code === 1){
          const result = res.data.data;
          if (result){
            this.codeForm.type = "addGroup"
          }else{
            this.codeForm.type = "addGroup"
          }
          this.getCode()
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    getCode(){
      /*发送验证码*/
      getMobileCode(this.codeForm).then(res =>{
        if (res.data.code === 1){
          this.$message.success('验证码发送成功！')
        }else {
          this.$message.error("验证码发送失败")
        }
      })
      this.msgText = MSGSCUCCESS.replace("${time}", String(this.msgTime));
      this.msgKey = true;
      const time = setInterval(() => {
        this.msgTime--;
        this.msgText = MSGSCUCCESS.replace("${time}", String(this.msgTime));
        if (this.msgTime === 0) {
          this.msgTime = MSGTIME;
          this.msgText = MSGINIT;
          this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    userJionQun(){
      /*校验数据*/
      let username = this.joinForm.username
      let code = this.joinForm.code
      let memberName = this.joinForm.memberName
      if (validatenull(username)){
        this.$message.warning("请输入手机号码")
        return
      }
      if(!isMobile(username)){
        this.$message.warning("手机号码格式不正确")
        return
      }
      if (validatenull(code)){
        this.$message.warning("请输入验证码")
        return
      }
      if (validatenull(memberName)){
        this.$message.warning("请输入群内名称")
        return
      }
      this.joinForm.qunId = this.qunInfo.id
      joinQunApply(this.joinForm).then(res =>{
        if (res.data.code === 1){
          this.$message.success('申请成功，请耐心等待审核！')
          this.coverBox = true
        }else {
          this.$message.error(res.data.msg)
        }
      })
    },
    closeBox(){
      this.coverBox = false
    }
  }
}
</script>

<style scoped>
  html,body{width: 100%;height: 100%;}
  .add-group-box{
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../assets/img/joinGroup/add-group-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .box-pc{
    width: 500px;
    height: 90%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #ffffff;
    border-radius: 39px;
    box-shadow:0px 0px 5px rgba(108,108,108,0.4);
  }
  .group-box-pc{
    width: 92%;
    height: 94%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .group-box-app{
    width: 100%;
    height: 100%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow:0px 0px 5px rgba(108,108,108,0.4);
    background: #ffffff;
  }
  .box-top{
    width: 100%;
    height: 30%;
    background-image: url("../../assets/img/joinGroup/top.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .box-bottom{
    width: 100%;
    height: 68%;
    background-image: url("../../assets/img/joinGroup/bottom.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .line-box-w90{
    width: 80%;
    height: 10%;
    margin: auto;
    box-shadow:0px 0px 8px rgba(69,86,95,0.4);
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .line-box-w90-app{
    width: 80%;
    height: 46px;
    margin: auto;
    box-shadow:0px 0px 8px rgba(69,86,95,0.4);
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .line-box-w90-app-noshadow{
    width: 80%;
    height: 36px;
    margin: auto;
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .line-btn-w90{
    width: 80%;
    height: 10%;
    line-height: 46px;
    margin: auto;
    border-radius: 36px;
    cursor: pointer;
  }
  .line-btn-w90 > input{
    width: 100%;
    height: 100%;
    margin: auto;
    border: none;
    border-radius: 36px;
    cursor: pointer;
  }
  .add-group-btn{
    width: 100%;
    height: 100%;
    color: #ffffff;
    border-radius: 36px;
    background: -webkit-gradient(linear, left top, right top, from(#27e1d2),to(#6078ea));
    background: linear-gradient(to right, #27e1d2, #6078ea);
    font-size: 22px;
    font-weight: 500;
    border: none;
    cursor: pointer;
  }
  .know-btn-box{
    width: 90%;
    height: 40%;
    padding-top: 20px;
    font-size: 22px;
    font-weight: 500;
    margin: auto;
  }
  .line-info-img{
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line-info-img > img{
    height: 32px;
    width: 32px;
  }
  .line-info-input{
    height: 100%;
    width: 85%;
  }
  .line-info-input >input{
    height: 90% !important;
    width: 92%;
    border: none;
    padding-left: 5%;
  }
  .line-info-input-code{
    height: 100%;
    width: 45%;
  }
  .line-info-input-code >input{
    height: 90%;
    width: 95%;
    border: none;
    padding-left: 5%;
  }
  .line-info-code-btn{
    height: 100%;
    width: 35%;
    padding-left: 5%;
  }
  .get-code-button{
    width: 100%;
    height: 70%;
    color: #ffffff!important;
    background: -webkit-gradient(linear, left top, right top, from(#27e1d2),to(#6078ea));
    background: linear-gradient(to right, #27e1d2, #6078ea);
    border-radius: 20px;
    border: none;
    margin-top: 6%;
  }
  .float-l{
    float: left;
  }
  .line-box-w100{
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .line-box-w100-app{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .line-box-seize{
    width: 100%;
    height: 4%;
  }
  .font-color{
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    background: -webkit-gradient(linear, left top, right top, from(#27e1d2),to(#6078ea));
    background: linear-gradient(to right, #27e1d2, #6078ea);
    -webkit-background-clip: text;
    color: transparent;
    /*text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;*/
  }
  .cover{
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    z-index: 3;
    position: absolute;
  }
  .success-box{
    width: 320px;
    height: 400px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #ffffff;
    border-radius: 30px;
  }
  .refuse-img-box{
    width: 280px;
    height: 280px;
    margin: auto;
    padding-top: 20px;
  }
  .refuse-img-box > img{
    width: 280px;
    height: 280px;
  }
  .refuse-info-box{
    width: 280px;
    height: 50px;
    margin: auto;
    line-height: 50px;
    color: #333333;
    text-align: center;
    font-size: 14px;
  }

  .success-img{
    width: 320px;
    height: 200px;
    background-image: url("../../assets/img/joinGroup/add-group-success.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .success-info{
    width: 320px;
    height: 100px;
  }
  .info01{
    width: 320px;
    height: 70px;
    line-height: 90px;
    color: #333333;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
  .info02{
    width: 320px;
    height: 30px;
    line-height: 30px;
    color: #bfbfbf;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  /*Input样式*/
  input::-webkit-input-placeholder {
    color: #ccc;
  }
  input:-moz-placeholder {
    color: #ccc;
  }
  input::-moz-placeholder {
    color: #ccc;
  }
  input:-ms-input-placeholder {
    color: #ccc;
  }
</style>
