import request from "@/utils/request";

export const authority =(obj) =>{
    return request({
        url: "/webgroupservice/managerAuthority/authority",
        method:"post",
        data:obj
    })
}

export const userAuthority =(obj) =>{
    return request({
        url: "/webgroupservice/managerAuthority/userAuthority",
        method:"post",
        data:obj
    })
}

export const listUserAuthority =(obj) =>{
    return request({
        url: "/webgroupservice/managerAuthority/listUserAuthority",
        method:"post",
        data:obj
    })
}


export const  deleteUserAuthority =(authId) =>{
    return request({
        url: "/webgroupservice/managerAuthority/delete?authId="+authId,
        method:"get",
    })
}
