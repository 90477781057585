<template>
    <div class="user-info-content">
      <el-form ref="userForm" :model="userForm" label-width="100px" label-position="right">
        <el-form-item label="头像">
          <el-upload :action="actionUrl"
                     :headers="headers"
                     :show-file-list="false"
                     :on-success="changeAvatarSuccess"
                     class="avatar-uploader">
            <el-image id="avatar" :src="avatarUrl" class="avatar" />
          </el-upload>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input class="input-width-30"  v-model="userForm.username" disabled />
        </el-form-item>
        <el-form-item label="真实姓名" prop="trueName">
          <el-input class="input-width-30"  v-model="userForm.trueName"/>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input class="input-width-30" v-model="userForm.nickName"/>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input class="input-width-30" v-model="userForm.email"/>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model="userForm.gender">
            <el-radio label="0" size="small">男</el-radio>
            <el-radio label="1" size="small">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="个人签名" prop="sign">
          <el-input class="input-width-30" v-model="userForm.sign" type="textarea"/>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn"  type="primary" @click="updateUserInfo">保存</el-button>
        </el-form-item>

      </el-form>
    </div>
</template>

<script>
import {updateUserInfo} from "@/api/admin/user"
import global from  "@/utils/global"
export default {
  name: "userInfo",
  data(){
    return{
      userForm:{
        username:"",
        nickName:"",
        trueName:"",
        email:"",
        gender:"",
        sign:"",
      },
      userInfo:{},
      avatarUrl:"",
      headers:{
        Authorization:"Bearer "+JSON.parse(localStorage.getItem("token"))
      },
      actionUrl:global.userAvatarUrl,
      global:global
    }
  },

  created() {
    this.userInfo = JSON.parse(this.$storage.get("userInfo"))
    this.setUserInfo()
  },
  methods:{
    setUserInfo(){
      this.userForm.username = this.userInfo.username
      this.userForm.nickName = this.userInfo.nickName
      this.userForm.trueName = this.userInfo.trueName
      this.userForm.email = this.userInfo.email
      this.userForm.gender = String(this.userInfo.gender)
      this.userForm.sign = this.userInfo.sign
      this.avatarUrl = this.global.fileUrl+"/user/head/"+this.userInfo.userId+".png_original?"+new Date().getTime()
    },
    updateUserInfo(){
      updateUserInfo(this.userForm).then(res => {
        if (res.data.code === 1){
          this.userInfo.nickName = this.userForm.nickName
          this.userInfo.trueName = this.userForm.trueName
          this.userInfo.email = this.userForm.email
          this.userInfo.gender = this.userForm.gender
          this.userInfo.sign = this.userForm.sign
          this.$storage.set("userInfo",JSON.stringify(this.userInfo));
          this.$message.success("修改成功")
        }else {
          this.$message.error("修改失败")
        }
      })
    },
    changeAvatarSuccess(){
      this.avatarUrl = this.global.fileUrl+"/user/head/"+this.userInfo.userId+".png_original?"+new Date().getTime()
      this.$message.success("头像上传成功")
    },

  }
}
</script>

<style scoped>
.user-info-content{
  width: 100%;
  height: 96%;
  background-color: #ffffff;
  padding:20px 0 0 0;
}
.input-width-10{
  width: 10%;
}
.input-width-20{
  width: 20%;
}
.input-width-30{
  width: 30%;
}
.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}
.form-btn{
  margin-left: 7%;
}
</style>
