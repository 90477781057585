<template>
  <div>
    <div class="my-icon">
      <img :src="require('@/assets/icon/img/' + name + '.png')" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    name: String,
  },
};
</script>

<style scoped>
.my-icon {
  width: 15px;
  height: 15px;
  margin-top: 3px;
}
.my-icon img {
  width: 100%;
  height: 100%;
}
</style>
