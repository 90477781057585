import request from "@/utils/request";


export const updateUserInfo =(obj) =>{
    return request({
        url: "/webgroupservice/user/update",
        method:"post",
        data:obj
    })
}
