import {timestampToTime} from "@/utils/date";

export const createZuOption = {
    labelWidth:120,
    emptyBtn:false,
    submitBtn:true,
    column: [
        {
            label:"所属",
            prop:"groupName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入组名称", trigger: "blur" }]
        },
        {
            label:"父组名称",
            prop:"parentZuName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入父组名称", trigger: "blur" }]
        },
        {
            label:"组名称",
            prop:"zuName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入组名称", trigger: "blur" }]
        },
    ]
}
export const createQunOption = {
    labelWidth:140,
    emptyBtn:false,
    submitBtn:true,
    column: [
        {
            label:"关联SAAS组织",
            prop:"groupName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输关联SAAS组织名称", trigger: "blur" }]
        },
        {
            label:"关联组",
            prop:"zuName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入关联组名称", trigger: "blur" }]
        },
        {
            label:"群名称",
            prop:"qunName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入群名称", trigger: "blur" }]
        },
    ]
}
export const addQunResourceOption = {
    labelWidth:140,
    emptyBtn:false,
    submitBtn:true,
    column: [
        {
            label:"群名称",
            prop:"qunName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入群名称", trigger: "blur" }]
        },
        {
            label:"资源",
            prop:"resourceIds",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入群名称", trigger: "blur" }]
        },
    ]
}

export const updateZuOption = {
    labelWidth:140,
    emptyBtn:false,
    submitBtn:true,
    column: [
        {
            label:"组名称",
            prop:"zuName",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请输入组名称", trigger: "blur" }]
        },
    ]
}

export const memberOption = {
    border:true,
    index:false,
    indexLabel:"#",
    indexLabelWidth:40,
    stripe:true,
    align:"center",
    emptyText: "该群暂无成员",
    searchMenuSpan:8,
    searchMenuPosition:"center",
    menuWidth:100,
    menuAlign:"center",
    labelWidth: 120,
    menuHeaderAlign:"center",
    viewBtn:false,
    editBtn:false,
    delBtn:false,
    addBtn:false,
    refreshBtn:false,
    columnBtn:false,
    menu:true,
    column:[
        {
            type:"input",
            label:"数据Id",
            prop:"id",
            hide:true,
        },
        {
            type:"input",
            label:"群成员账号",
            prop:"username",
        },
        {
            type:"input",
            label:"成员名称",
            prop:"memberName",
        },
        {
            type:"input",
            label:"入群时间",
            prop:"joinTime",
            formatter:(val,value,label)=>{
                return timestampToTime(value)
            },
        },
    ]
}


export const recordOption = {
    border:true,
    index:false,
    indexLabel:"#",
    indexLabelWidth:40,
    stripe:true,
    align:"center",
    emptyText: "该群暂无书写智能本",
    searchMenuSpan:8,
    searchMenuPosition:"center",
    menuWidth:100,
    menuAlign:"center",
    labelWidth: 120,
    menuHeaderAlign:"center",
    viewBtn:false,
    editBtn:false,
    delBtn:false,
    addBtn:false,
    refreshBtn:false,
    columnBtn:false,
    menu:false,
    column:[
        {
            type:"input",
            label:"数据Id",
            prop:"id",
            hide:true,
        },
        {
            type:"input",
            label:"智能本名称",
            prop:"recordName",
        },
        {
            type:"select",
            label:"是否共享",
            prop:"isShare",
            slot:true,
            dicData:[
                {label:"否",value:0},
                {label:"是",value:1}
            ]
        },
        {
            type:"select",
            label:"是否归档",
            prop:"isStoraged",
            slot:true,
            dicData:[
                {label:"否",value:0},
                {label:"是",value:1}
            ]
        },
    ]
}
/**************************************表单*****************************************/
/*新建*/
export const createZuForm = {
    groupId:"",
    groupName:"",
    parentZuId:"",
    parentZuName:"",
    zuName:"",
}

export const createQunForm = {
    /*关联SAAS组织信息*/
    groupId:"",
    groupName:"",
    /*组信息*/
    zuId:"",
    zuName:"",
    /*群信息*/
    qunName:"",
}

export const updateQunForm = {
    /*群信息*/
    qunId:"",
    qunName:"",
    qunCode:"",
}
export const updateZuForm = {
    /*组信息*/
    zuId:"",
    zuName:"",
}
export const addQunResourceForm = {
    qunId:"",
    qunName:"",
    resourceIds:[],
}
/*分享二维码*/
export const qrCode = {
    shareUrl:"",
    size:120
}
export const pageMember = {
    total:0,
    pageSizes: [10, 20, 40],
    layout: "total,sizes,prev,pager,next,jumper",
    currentPage:1,
    pageSize:10
}
export const pageRecord = {
    total:0,
    pageSizes: [10, 20, 40],
    layout: "total,sizes,prev,pager,next,jumper",
    currentPage:1,
    pageSize:10
}
export const searchMember = {
    qunId:""
}

export const searchRecord = {
    qunId:""
}
/**************************************校验规则*****************************************/
export const updateQunRules = {
    qunName: [{ required: true, message: '请输入群名称', trigger: 'blur' },],
    qunCode: [{ required: true, message: '请输入群Code', trigger: 'blur' },],
}

export const updateZuRules = {
    zuName: [{ required: true, message: '请输入组名称', trigger: 'blur' },],
}
