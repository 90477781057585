<template>
  <div id="home-box">
    <el-container>
      <el-aside>
        <div class="zbh-logo">群组管理平台</div>
        <el-menu
            unique-opened
            :default-active="activePath"
            class="el-menu-vertical-demo"
            background-color="#20222a"
            text-color="#ffffff"
            router
            @open="handleOpen"
            @close="handleClose">
          <!--一级菜单-->
          <el-sub-menu v-for="menu in menusList" :index="menu.id" :key="menu.id">
            <template #title>
              <i :class="menu.icon"/>
              <span>{{menu.name}}</span>
            </template>
            <!--二级菜单-->
            <el-menu-item v-for="item in menu.children" :index="item.path" :key="item.id" @click="saveNavState(item.path)">
              <template #title>
                <i :class="item.icon"/>
                <span>{{item.name}}</span>
              </template>
            </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <!--侧边栏-->
      <el-container>
        <!--顶部菜单-->
        <el-header>
          <el-row  type="flex" align="middle">
            <!--左侧组织切换-->
            <el-col :span="12">
              <el-row type="flex" align="middle">
                <el-col :span="24" align="left">
                  <el-dropdown @command="changeGroup">
                    <el-button type="primary">
                      {{account.groupName}}
                      <el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                            v-for="(item,index) in accountList"
                            :command="item"
                            :key="index">
                          {{item.groupName}}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-col>
              </el-row>
            </el-col>
            <!--个人中心及消息通知-->
            <el-col :span="12">
              <el-row justify="end" type="flex" align="middle">
                <el-col :span="2">
                  <svg t="1652837604915" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5754" width="20" height="20">
                    <path d="M861.575529 783.058824a30.117647 30.117647 0 0 1-30.117647-30.117648V378.096941C831.457882 202.541176 689.152 60.235294 513.626353 60.235294 338.070588 60.235294 195.764706 202.541176 195.764706 378.096941V752.941176a30.117647 30.117647 0 0 1-30.117647 30.117648H105.411765v60.235294h813.17647v-60.235294h-57.012706zM918.588235 722.823529a60.235294 60.235294 0 0 1 60.235294 60.235295v60.235294a60.235294 60.235294 0 0 1-60.235294 60.235294H105.411765a60.235294 60.235294 0 0 1-60.235294-60.235294v-60.235294a60.235294 60.235294 0 0 1 60.235294-60.235295h30.117647V378.096941C135.529412 169.261176 304.790588 0 513.626353 0c208.805647 0 378.066824 169.261176 378.066823 378.096941V722.823529H918.588235z m-481.882353 210.82353h180.705883a90.352941 90.352941 0 0 1-180.705883 0z" fill="#2489f2" p-id="5755"></path>
                  </svg>
                </el-col>
                <el-col :span="2">
                  <el-dropdown>
                    <el-avatar :size="35" :src="userHeadImg" />
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item  @click="userCenter">个人中心</el-dropdown-item>
                        <el-dropdown-item  @click="logout">安全退出</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-header>
        <!--展示区-->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import global from  "@/utils/global"
import {menuList} from "@/api/admin/menu"
export default {
  name:"groupHome",
  data(){
    return{
      activePath: "",
      userHeadImg:"",
      userInfo:{},
      menusList: [],
      accountList:[],
      account:{},
      global:global
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.userInfo = JSON.parse(this.$storage.get("userInfo"))
    this.accountList = JSON.parse(this.$storage.get("groupAccounts"))
    this.account = JSON.parse(this.$storage.get("lastGroupAccount"))
    this.userHeadImg = this.global.fileUrl+"/user/head/"+this.userInfo.userId+".png_original?"+new Date().getTime()
    this.listMenu()
  },
  methods:{
    listMenu(){
      menuList().then(res =>{
        if (res.data.code === 1){
          this.menusList = res.data.data
        }else {
          this.$message.error("菜单加载失败")
        }
      })
    },
    handleOpen:function (){},
    handleClose:function (){},
    changeGroup(account){
      this.account = account
      this.$storage.set("lastGroupAccount",JSON.stringify(account));
      this.$addStorageEvent("saasGroup",account);
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    logout(){
      this.$confirm("您正在退出系统，是否继续？","退出系统",{
        confirmButtonText:"退出",
        cancelButtonText:"取消",
        type:"warning"
      }).then(() =>{
        this.$storage.remove("token")
        this.$storage.remove("userInfo")
        this.$storage.remove("userInfo")
        this.$storage.remove("userInfo")
        this.$router.push({path:"/login"});
      })
    },
    userCenter(){
      this.$router.push("/user")
    }
  }
}
</script>
<!--内联样式-->
<style lang="scss" scoped>
#home-box{
  width: 100%;
  height: 100%;
  .el-main{
    background: #f7f8f8;
  }

  .el-container {
    height: 100%;
  }
  .el-aside{
    width: 20%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    .zbh-logo{
      height: 8%;
      color: #F9F8F8;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: justify;
      background-color: #20222a;
    }
    .el-menu{
      border: none;
      height: 92%;
    }
  }
  .el-header{
    padding: 0 0;
    text-align: center;
    height: 60px !important;
    width: 99%!important;
    border-bottom: 1px solid #F9F8F8;
    .el-row{
      height: 60px !important;
    }
    .el-dropdown{
      margin-left: 10px!important;
    }
  }
}

</style>
