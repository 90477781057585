import request from "@/utils/request";

export const  saasResourceList =(accountId) =>{
    return request({
        url: "/webgroupservice/book/listSaasBook?accountId="+accountId,
        method:"get",
    })
}

export const  bookInfo =(resourceId) =>{
    return request({
        url: "/webgroupservice/book/info?resourceId="+resourceId,
        method:"get",
    })
}
