import { createApp } from 'vue'
import App from '@/App.vue'
import zbhRouter from '@/router'
import ElementPlus  from 'element-plus'
import axios from 'axios'
import 'element-plus/dist/index.css'
/*图标*/
import * as ElementPlusIcons from '@element-plus/icons-vue'
import "@/assets/icon/iconfont.css"
/*国际化*/
import zhCn from 'element-plus/es/locale/lang/zh-cn'
/*工具类*/
import {Encrypt} from "@/utils/secret"
import Storage from "@/utils/storage"
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
/*消息提示*/
import { ElMessage ,ElMessageBox } from "element-plus";
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIcons)) {
    app.component(key, component)
}
/*全局配置*/
app.config.globalProperties.$encrypt = Encrypt
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$storage = Storage
app.config.globalProperties.$router = zbhRouter
window.$router=zbhRouter
app.config.globalProperties.$confirm = ElMessageBox
/*监听缓存*/
app.config.globalProperties.$addStorageEvent = function(key, data) {
        let newStorageEvent = document.createEvent("StorageEvent");
        const storage = {
            setItem: function(k, val) {
                localStorage.setItem(k, val);
                newStorageEvent.initStorageEvent(
                    "changeSaasGroup",
                    false,
                    false,
                    k,
                    null,
                    val,
                    null,
                    null
                );
                window.dispatchEvent(newStorageEvent);
            },
        };
        return storage.setItem(key, data);
};

app.use(zbhRouter)
app.use(ElementPlus ,{locale: zhCn,})
app.use(Avue)
app.use(Avue,{axios})
app.mount('#app')
