import request from "@/utils/request";

export const  zuList =(groupId) =>{
    return request({
        url: "/webgroupservice/zu/list?groupId="+groupId,
        method:"get",
    })
}

export const  createZu =(obj) =>{
    return request({
        url: "/webgroupservice/zu/create",
        method:"post",
        data:obj
    })
}

export const  deleteZu =(zuId) =>{
    return request({
        url: "/webgroupservice/zu/delete?id="+zuId,
        method:"get",
    })
}
export const  updateZu =(obj) =>{
    return request({
        url: "/webgroupservice/zu/update",
        method:"post",
        data:obj
    })
}
