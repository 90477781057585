<template>
  <div id="login-box">
    <el-container>
      <!--左侧-->
      <el-aside></el-aside>
      <el-container>
        <!--顶部-->
        <el-header>
          <el-row justify="end" type="flex">
            <el-col :span="4">
              <el-link :underline="false" href="https://www.zbform.com" target="_blank">
                <el-icon><connection /></el-icon>&nbsp;孜博汇官网
              </el-link>
            </el-col>
          </el-row>
        </el-header>
        <!--登录框-->
        <el-main>
          <div id="login-form">
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules">
              <p class="login-type">验证码登录</p>
              <el-form-item prop="username">
                <el-input type="text" v-model="loginForm.username" placeholder="请输入注册账号" prefix-icon="User"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input type="text"
                          v-model="loginForm.code"
                          placeholder="请输入6位数字验证码"
                          prefix-icon="Iphone">
                  <template #append>
                    <el-button class="code-btn" type="primary" @click="getCode" :loading="codeBtnLoading">{{ msgText }}</el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="loginBtn" type="primary" @click="subUserLogin" :loading="loginBtnLoading">登录</el-button>
              </el-form-item>
            </el-form>
            <div class="other-tab">
              <el-row>
                <el-col class="tab-one" :span="8" style="text-align: left;"><a @click="toPwdLogin">密码登录</a></el-col>
                <el-col class="tab-one" :span="8" style="text-align: center;"><a @click="toRes">注册</a></el-col>
                <el-col class="tab-one" :span="8" style="text-align: right;"><a @click="toResetPwd">重置密码</a></el-col>
              </el-row>
            </div>
          </div>
        </el-main>
        <!--底部-->
        <el-footer>
          <el-row justify="center" type="flex">
            <el-col :span="16">
              版权所有©南京孜博汇信息科技有限公司  |  苏ICP备2020063340号
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>

const MSGINIT = "获取验证码";
const MSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 60;
import {codeLogin,isAuthGroup,getMobileCode } from "@/api/user/login";
let validateUsername = (rule,value,callback) =>{
  isAuthGroup(value).then(res =>{
    const result = res.data.data
    if (!result){
      callback(new Error("该账号暂无权限登录"))
    }else {
      callback()
    }
  })
}
export default ({
  name:"codeLogin",
  data(){
    return{
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      codeBtnLoading:false,
      loginBtnLoading:false,
      /*登录数据绑定对象*/
      loginForm:{
        username:"",
        code:"",
        type:"login",
      },
      codeForm:{
        mobile:"",
        type:"login"
      },
      /*登录校验规则*/
      loginRules:{
        username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
          { validator:validateUsername,trigger: "blur"}
        ],
        code: [{ required: true, message: "请输入6位数字验证码", trigger: "blur" }],
      }
    }
  },
  created() {
    let that = this
    document.onkeydown = function (e){
      e = window.event || e ;
      if (e.code ==="Enter" || e.code==="enter"){
        that.subUserLogin();
      }
    }
  },
  methods:{
    subUserLogin(){
      this.$refs.loginFormRef.validate( valid => {
        if (!valid) return
        this.loginBtnLoading = true
        codeLogin(this.loginForm).then(res => {
          if (res.data.code === 1){
            const info = res.data.data
            debugger
            this.$storage.set("userInfo",JSON.stringify(info.userInfo));
            this.$storage.set("token",info.token);
            this.$storage.set("lastGroupAccount",JSON.stringify(info.lastGroupAccount));
            this.$storage.set("groupAccounts",JSON.stringify(info.groupAccounts));
            this.loginBtnLoading = false
            this.$router.push("/home")
          }else {
            this.loginBtnLoading = false
            this.$message.error("验证码错误")
          }
        })
      })
    },
    toRes(){
      window.location.href = "https://zhuce.zbform.com"
    },
    toPwdLogin(){
      this.$router.push("/login")
    },
    toResetPwd(){
      this.$router.push("/resetPwd")
    },
    /*获取验证码*/
    getCode(){
      if (this.msgKey) return;
      if(this.loginForm.username === ''){
        this.$message.error("请输入注册手机号")
        return;
      }
      this.codeBtnLoading = true
      this.codeForm.mobile = this.loginForm.username
      getMobileCode(this.codeForm).then(res => {
        if (res.data.code === 1){
          this.$message.success("验证码发送成功")
          this.codeBtnLoading = false
          this.codeTimer()
        }else {
          this.$message.error(res.data.msg)
        }
      })
    },
    codeTimer(){
      this.msgText = MSGSCUCCESS.replace("${time}",this.msgTime)
      this.msgKey = true
      const timer = setInterval(() => {
          this.msgTime--
          this.msgText = MSGSCUCCESS.replace("${time}",this.msgTime)
          if (this.msgTime == 0){
            this.msgTime = MSGTIME
            this.msgText = MSGINIT
            this.msgKey = false
            clearInterval(timer)
          }
        },1000)
    }
  }
})
</script >
<!--样式-->
<style lang="scss" scoped>
@import url(../../styles/login.scss);
/*登录框*/
#login-box{
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
  }
  .el-header{
    padding: 0 0;
    font-size: 18px;
    text-align: center;
    height: 80px !important;
    .el-link{
      color: #4c596e;
      text-decoration:none;
    }
    .el-link:hover{
      color: #2489f2;
      text-decoration:none;
    }
  }
  .el-footer {
    color: #4c596e;
    text-align: center;
    height: 40px !important;
    font-size: 14px;
  }
  .el-aside {
    width: 34%;
    background-image: url("../../assets/login_l_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .el-main {
    padding: 0 0;
    position: relative;
  }
  .el-row {
    height: 100%;
    align-items: center;
  }
  .el-col {
    child-align: middle;
  }
}
.tab-one{
  cursor: pointer;
  font-size: 14px;
  color: #7f8997;
}
.tab-one > a:hover{
  color: #428bfa;
}
.code-btn{
  background-color: #428bfa!important;
  color: #ffffff!important;
  font-size: 13px!important;
}
</style>

