<template>
  <div class="pdf-preview">
    <div class="pdf-wrap">
      <vue-pdf-embed :source="source" :style="scaleFun" :page="pageNum" />
    </div>
    <div class="page-tool">
      <div class="page-tool-item" @click="lastPage">上一页</div>
      <div class="page-tool-item">{{ pageNum }}/{{ numPages }}</div>
      <div class="page-tool-item" @click="nextPage">下一页</div>
      <!--      <div class="page-tool-item" @click="pageZoomOut">放大</div>
      <div class="page-tool-item" @click="pageZoomIn">缩小</div>-->
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import { createLoadingTask } from "vue3-pdfjs/esm"; // 获得总页数

export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      source: "https://group.zbform.com/file/workBook.pdf",
      // source: "/workBook.pdf",

      pageNum: 1,
      scale: 1,  // 缩放比例
      numPages: 0, // 总页数
    };
  },
  mounted() {

    const loadingTask = createLoadingTask(this.source);
    debugger
    loadingTask.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
  computed: {
    scaleFun(index) { // 缩放
      let scale = this.scale;
      return `transform:scale(${scale})`;
    },
  },
  methods: {
    lastPage() {
      if (this.pageNum > 1) {
        this.pageNum -= 1;
      }
    },
    nextPage() {
      if (this.pageNum < this.numPages) {
        this.pageNum += 1;
      }
    },
    pageZoomOut() {
      if (this.scale < 2) {
        this.scale += 0.1;
      }
    },
    pageZoomIn() {
      if (this.scale > 1) {
        this.scale -= 0.1;
      }
    }
  },
};
</script>

<style scoped>
.pdf-preview {
  position: relative;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #e9e9e9;
  height: 100%;
  width: 98%;
  margin: auto;
}

.pdf-wrap {
  overflow-y: auto;
}

.vue-pdf-embed {
  text-align: center;
  width: 100%;
  border: 1px solid #e5e5e5;
  margin: 0 auto;
  box-sizing: border-box;
}

.page-tool {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  background: rgb(66, 66, 66);
  color: white;
  border-radius: 19px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.page-tool-item {
  padding: 5px 5px;
  cursor: pointer;
}
</style>
