<template>
 <div>群管理</div>
</template>

<script>
export default {
  name: "groupManage"
}
</script>

<style scoped>

</style>
