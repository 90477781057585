import {timestampToTime} from "@/utils/date"
export const applyOption = {
    border:true,
    index:false,
    indexLabel:"#",
    indexLabelWidth:40,
    stripe:true,
    align:"center",
    searchMenuSpan:8,
    searchMenuPosition:"center",
    menuWidth:200,
    menuAlign:"center",
    labelWidth: 120,
    menuHeaderAlign:"center",
    viewBtn:false,
    editBtn:false,
    delBtn:false,
    addBtn:false,
    refreshBtn:false,
    columnBtn:false,
    menu:true,
    column:[
        {
            type:"input",
            label:"唯一标识",
            prop:"id",
            hide:true,
            editDisplay:false,
            addDisplay:false,
            viewDisplay:false
        },
        {
            type:"input",
            label:"申请人手机号",
            prop:"username",
            hide:false,
            editDisplay:false,
            addDisplay:false,
            viewDisplay:false
        },
        {
            type:"input",
            label:"成员名称",
            prop:"memberName",
            hide:false,
            editDisplay:false,
            addDisplay:false,
            viewDisplay:false
        },
        {
            type:"select",
            label:"申请状态",
            prop:"dealResult",
            slot:true,
            editDisplay:false,
            dicData:[
                {label:"未处理",value:0},
                {label:"已同意",value:1},
                {label:"已拒绝",value:2}
            ]
        },
    ]
}
export const memberOption = {
    border:true,
    index:false,
    indexLabel:"#",
    indexLabelWidth:40,
    stripe:true,
    align:"center",
    searchMenuSpan:8,
    searchMenuPosition:"center",
    menuWidth:200,
    menuAlign:"center",
    labelWidth: 120,
    menuHeaderAlign:"center",
    viewBtn:false,
    editBtn:false,
    delBtn:false,
    addBtn:false,
    refreshBtn:false,
    columnBtn:false,
    menu:true,
    column:[
        {
            type:"input",
            label:"数据Id",
            prop:"id",
            hide:true,
        },
        {
            type:"input",
            label:"群成员账号",
            prop:"username",
        },
        {
            type:"input",
            label:"群内名称",
            prop:"memberName",
        },
        {
            type:"input",
            label:"入群时间",
            prop:"joinTime",
            formatter:(val,value,label)=>{
                return timestampToTime(value)
            },
        },
    ]
}
export const authOption = {
    labelWidth:120,
    emptyBtn:true,
    submitBtn:true,
    column: [
        {
            label:"组名称",
            prop:"zuId",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请选择组", trigger: "blur" }]
        },
        {
            type:"select",
            label:"群名称",
            prop:"群Id",
            formslot:true,
            labelslot:true,
            rules: [{ required: true, message: "请选择群", trigger: "blur" }]
        },
    ]
}
export const casOption = {
    value: 'id',
    label: 'zuName',
    children:[]
}
export const authList = [
    {
        value: 'view',
        name: '浏览查看',
    },
    {
        value: 'member',
        name: '成员管理',
    },
    {
        value: 'notice',
        name: '公告管理',
    },
    {
        value: 'record',
        name: '智能本管理',
    },
    {
        value: 'task',
        name: '任务管理',
    },
]
/**************************************表单*****************************************/
/*群信息更新表单*/
export const updateForm = {
    qunId:"",
    qunName:"",
    qunCode:"",
}
/*同意或拒绝申请*/
export const dealForm = {
    accountId:"",
    applyId:"",
    dealResult:""
}
export const qunAuthForm = {
    authId:"",
    groupId:"",
    zuId: "",
    qunId:"",
    userId:"",
    username:"",
    managerName:"",
    authoritys:[],
}
export const userAuthForm = {
    qunId:"",
    userId:"",
}
/*分享二维码*/
export const qrCode = {
    shareUrl:"",
    size:120
}
/*搜索*/
export const searchApply = {
    qunId:""
}
export const searchMember = {
    qunId:""
}
/*分页*/
export const pageApply = {
    total:0,
    pageSizes: [10, 20, 40],
    layout: "total,sizes,prev,pager,next,jumper",
    currentPage:1,
    pageSize:10
}
export const pageMember = {
    total:0,
    pageSizes: [10, 20, 40],
    layout: "total,sizes,prev,pager,next,jumper",
    currentPage:1,
    pageSize:10
}
/*校验规则*/
export const updateRules = {
    qunCode:[{required:true,message:"群编号不能为空",trigger:"blur"}],
    qunName:[{required:true,message:"请输入管理员群名称",trigger:"blur"}],
}

export const qunAuthRules = {
    qunId:[{required:true,message:"请选择群",trigger:"change"}],
    zuId:[{required:true,message:"请选择组",trigger:"change"}],
    /*authoritys:[{required:true,message:"请选择权限",trigger:"change"}],*/
}

export const adminInfoRules = {
    memberName:[{required:true,message:"成员名称不能为空",trigger:"blur"}],
}
