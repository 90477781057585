import request from "@/utils/request";

export const  qunResourceList =(qunId) =>{
    return request({
        url: "/webgroupservice/qunResource/list?qunId="+qunId,
        method:"get",
    })
}

export const addQunResource =(obj) =>{
    return request({
        url: "/webgroupservice/qunResource/create",
        method:"post",
        data:obj
    })
}

export const  deleteResource=(resourceId) =>{
    return request({
        url: "/webgroupservice/qunResource/delete?resourceId="+resourceId,
        method:"get",
    })
}
