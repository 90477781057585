import request from "@/utils/request";
/*群成员列表*/
export const  memberPageList =(query) =>{
    return request({
        url: "/webgroupservice/qunMember/pageList",
        method:"get",
        params:query
    })
}
/*踢出管理员*/
export const  deleteAdmin =(memberId) =>{
    return request({
        url: "/webgroupservice/qunMember/deleteAdmin?memberId="+memberId,
        method:"get",
    })
}

/*更新群成员名称*/
export const  renameMember =(obj) =>{
    return request({
        url: "/webgroupservice/qunMember/renameMember",
        method:"post",
        data:obj
    })
}

/*查询组织下非管理员成员列表*/
export const  commonMember =(obj) =>{
    return request({
        url: "/webgroupservice/qunMember/commonMember",
        method:"post",
        data:obj
    })
}

/*批量添加管理员*/
export const  addAdminMember =(obj) =>{
    return request({
        url: "/webgroupservice/qunMember/addAdminMember",
        method:"post",
        data:obj
    })
}
/*群成员书写情况*/
export const  memberWriteInfo =(obj) =>{
    return request({
        url: "/webgroupservice/qunMember/writeInfo",
        method:"post",
        data:obj
    })
}
