/*路由配置*/
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import UserLogin from "@/views/public/userLogin";
import CodeLogin from "@/views/public/codeLogin"
import ResetPwd from "@/views/public/resetPwd"
import GroupHome from "@/views/groupHome"
import WelHome from "@/views/public/welHome"
import OrganizeManage from "@/views/zbh/organize/organizeManage"
import GroupManage from "@/views/zbh/group/groupManage"
import JoinGroup from "@/views/public/joinGroup"
import AdminGroup from "@/views/zbh/adminGroup/adminGroupManage"
import UserInfo from  "@/views/zbh/user/userInfo"
import GroupInfo from "@/views/zbh/user/groupInfo"
import Policy from "@/views/public/policy"
import WorkBook from "@/views/public/workBook"
import WriteInfo from "@/views/zbh/write/writeInfo"
import ChooseHelp from "@/views/public/chooseHelp"
import workVideo from "@/views/public/workVideo"

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', name: '用户账号登录',meta: { title: '孜博汇-用户账号登录' }, component: UserLogin },
    { path: '/codeLogin', name: '用户验证码登录',meta: { title: '孜博汇-用户验证码登录' }, component: CodeLogin },
    { path: '/resetPwd', name: '用户重置密码',meta: { title: '孜博汇-用户重置密码' }, component: ResetPwd },
    { path: '/joinGroup', name: '用户加群',meta: { title: '孜博汇-用户加群' }, component: JoinGroup },
    // { path: '/policy', name: '群笔记隐私政策',meta: { title: '孜博汇-群笔记隐私政策' }, component: Policy },
    // { path: '/workBook', name: '群笔记操作手册',meta: { title: '孜博汇-群笔记操作手册' }, component: WorkBook },
    // { path: '/chooseHelp', name: '云尚记事-使用帮助',meta: { title: '云尚记事-使用帮助' }, component: ChooseHelp },
    { path: '/workVideo', name: '群笔记操作视频',meta: { title: '群笔记操作视频' }, component: workVideo },

    {
        path: '/home',
        name:"首页",
        component: GroupHome,
        redirect: "/welHome",
        meta: { title: '首页-孜博汇管理中心' },
        children:[
            {path:"/welHome", name:"用户总览",meta: { title: '孜博汇-组织总览' }, component:GroupInfo},
            {path:"/groupManage", name:"群管理",meta: { title: '孜博汇-群管理' }, component:GroupManage},
            {path:"/organizeManage", name:"组管理",meta: { title: '孜博汇-群组管理' }, component:OrganizeManage},
            {path:"/adminGroup", name:"管理权限",meta: { title: '孜博汇-管理员群' }, component:AdminGroup},
            {path:"/user", name:"个人信息",meta: { title: '孜博汇-个人信息' }, component:UserInfo},
            {path:"/group", name:"组织信息",meta: { title: '孜博汇-组织信息' }, component:GroupInfo},
            {path:"/write", name:"智能本管理",meta: { title: '孜博汇-智能本管理' }, component:WriteInfo},
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/login' ||
        to.path === '/codeLogin' ||
        to.path === '/resetPwd' ||
        to.path === '/joinGroup'||
        to.path === '/workBook'||
        to.path === '/chooseHelp'||
        to.path === '/workVideo'||
        to.path === '/policy') return next()
    // 获取token
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/login')
    window.document.title = to.meta.title
    next()
})

export default router
