<template>
    <div style="max-width:500px;height: 100%;margin: 0 auto;font-size:13px">
        <template v-for="item in videoList">
            <p style="text-align: center;line-height: 50px;font-weight: bold;font-size: 16px;">{{ item.title }}
            </p>
            <video :poster="item.src + '?x-oss-process=video/snapshot,t_1,m_fast'" x5-video-player-fullscreen="true"
                x5-playsinline playsinline webkit-playsinline :src="item.src" style="width:100%;height:230px;"
                controls=""></video>
        </template>

        <div style="position:  relative;margin-bottom: 35px;margin-top:10px;font-size: 6px;text-align:center;">
            <span>版权所有©</span><a href="https://www.zbform.com" target="_blank" rel="noopener" style="text-decoration:none">
                <span style="color: #4eac66;">南京孜博汇信息科技有限公司</span>
            </a>
            <br>
            <span>
                <a href="http://beian.miit.gov.cn/" style="color: #333333; text-decoration: none;" target="_blank"
                    rel="noopener">苏ICP备2020063340号</a>
            </span>
        </div>
    </div>
</template>
  
<script>
export default ({
    name: "workVideo",
    data() {
        return {
            videoList: [
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E7%99%BB%E5%BD%951.mp4',
                    title: '登录1'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E7%99%BB%E5%BD%952.mp4',
                    title: '登录2'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E6%89%8B%E5%8A%A8%E8%BF%9E%E7%AC%941.mp4',
                    title: '手动连笔1'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E6%89%8B%E5%8A%A8%E8%BF%9E%E7%AC%942.mp4',
                    title: '手动连笔2'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E4%BB%96%E4%BA%BA%E4%BA%91%E6%9C%AC.mp4',
                    title: '他人云本'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E6%88%91%E7%9A%84.mp4',
                    title: '我的'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E6%9C%AC%E5%AD%90%E5%86%85%E9%A1%B5%E8%AF%A6%E6%83%85.mp4',
                    title: '本子内页详情'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E6%9F%A5%E7%9C%8B%E3%80%81%E5%AE%8C%E6%88%90%E4%BB%BB%E5%8A%A1.mp4',
                    title: '查看、完成任务'
                },
                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E6%9F%A5%E7%9C%8B%E3%80%81%E6%89%B9%E9%98%85%E4%BB%BB%E5%8A%A1.mp4',
                    title: '查看、批阅任务'
                },

                {
                    src: 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/ysqbj/%E7%BE%A4%E7%BB%84.mp4',
                    title: '群组'
                },
            ]
        }
    },
    created() {
    },
    methods: {

    }
})
</script >
  <!--样式-->
<style lang="scss" scoped></style>
  
  