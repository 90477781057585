import request from "@/utils/request";

/*群书写记录（智能本）列表*/
export const  recordPageList =(query) =>{
    return request({
        url: "/webgroupservice/qunRecord/pageList",
        method:"get",
        params:query
    })
}

export const  qunRecordAndMember =(qunId) =>{
    return request({
        url: "/webgroupservice/qunRecord/member?qunId="+qunId,
        method:"get",
    })
}
